import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { agregarReservacion } from '../services/Services';
import Swal from 'sweetalert2'; 


const ReservationModal = ({ isOpen, onClose, formData, handleInputChange, idSeccion, idServicio }) => {
  if (!isOpen) return null;

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const datosFormulario = {
      ...formData,
      idSeccion: idSeccion, 
      idServicio: idServicio, 
    };
    try {
      await agregarReservacion(datosFormulario);
      onClose(); 
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al agregar la reservación',
        text: error.message || 'Hubo un problema al agregar la reservación. Intenta de nuevo.',
      });
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{ zIndex: 1000 }}>
      <div className="bg-white rounded-lg p-4 w-full max-w-md">
        <h2 className="text-xl font-semibold mb-3 text-center">Solicitar Reservación</h2>
        <form onSubmit={handleSubmit} className="space-y-3">
          
          <div>
            <label htmlFor="nombre" className="block text-xs font-medium text-gray-700">Nombre</label>
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleInputChange}
              className="w-full border-b-2 border-gray-300 focus:border-blue-500 outline-none py-1.5"
              placeholder="Ej. Juan Pérez"
              required
            />
          </div>
  
          <div className="mb-2 flex items-center">
            <label className="block mb-1 text-xs" htmlFor="telefono">Teléfono</label>
          </div>
          <div className="mb-2">
            <PhoneInput
              country={'mx'}
              value={formData.telefono}
              onChange={(value) => handleInputChange({ target: { name: 'telefono', value } })}
              inputStyle={{
                width: '100%',
                border: 'none',
                borderBottom: '2px solid #d1d5db',
                borderRadius: '0',
                padding: '0.5rem',
                paddingLeft: '2.5rem',
              }}
              dropdownStyle={{ maxHeight: '150px' }}
              placeholder="Ej. 5551234567"
              required
            />
          </div>
  
          <div className="mb-2 flex items-center">
            <label className="block mb-1 text-xs" htmlFor="whatsapp">WhatsApp</label>
          </div>
          <div className="mb-2">
            <PhoneInput
              country={'mx'}
              value={formData.whatsapp}
              onChange={(value) => handleInputChange({ target: { name: 'whatsapp', value } })}
              inputStyle={{
                width: '100%',
                border: 'none',
                borderBottom: '2px solid #d1d5db',
                borderRadius: '0',
                padding: '0.5rem',
                paddingLeft: '2.5rem',
              }}
              dropdownStyle={{ maxHeight: '150px' }}
              placeholder="Ej. 5559876543"
              required
            />
          </div>
  
          <div>
            <label htmlFor="email" className="block text-xs font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full border-b-2 border-gray-300 focus:border-blue-500 outline-none py-1.5"
              placeholder="Ej. correo@ejemplo.com"
              required
            />
          </div>
  
          <div>
            <label htmlFor="comentarios" className="block text-xs font-medium text-gray-700">Comentarios</label>
            <textarea
              name="comentarios"
              value={formData.comentarios}
              onChange={handleInputChange}
              className="w-full border-b-2 border-gray-300 focus:border-blue-500 outline-none py-1.5 resize-none"
              rows="3"
              placeholder="Ej. Información adicional sobre la reservación..."
            />
          </div>
  
          <div className="flex justify-center space-x-3 mt-4">
            <button type="submit" className="bg-blue-600 text-white py-1.5 px-4 rounded-lg hover:bg-blue-700 transition duration-300">
              Enviar Reserva
            </button>
            <button 
              type="button" 
              onClick={onClose} 
              className="bg-gray-500 text-white py-1.5 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );  
};

export default ReservationModal;
