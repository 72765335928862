import React from 'react';

const Footer = () => {
  return (
    <footer id="contacto" className="bg-[#009fe3] py-4 w-full">
      {/* Contenedor principal */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full px-4">
        {/* Sección del lado izquierdo: Logo y Redes Sociales */}
        <div className="flex flex-col items-start md:w-1/3">
          <img src="images/logoBlanco.svg" alt="HeavenlyTrip" className="h-12 mb-2" />
          <div className="flex space-x-3 mb-1">
            <a href="https://facebook.com/share/Sbm3Q9R3YrEWFYcc" target="_blank" rel="noopener noreferrer">
              <img src="images/facebook.png" alt="Facebook" className="h-5" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <img src="images/instagram.png" alt="Instagram" className="h-5" />
            </a>
          </div>
          <p className="text-[#FFFFFF] text-sm">aheavenlytrip1@gmail.com</p>
        </div>

        {/* Sección central: Matriz y Sucursal */}
        <div className="flex flex-col md:flex-row justify-center items-center text-center md:w-1/3 space-y-2 md:space-y-0 md:space-x-8">
          <div>
            <h3 className="text-[#FFFFFF] text-lg font-bold">Matriz</h3>
            <p className="text-[#FFFFFF] text-sm">Cancún, Quintana Roo</p>
            <p className="text-[#FFFFFF] text-sm">CP: 77518</p>
            <p className="text-[#FFFFFF] text-sm">Tel: +52 99 8732 3771</p>
          </div>

          <div>
            <h3 className="text-[#FFFFFF] text-lg font-bold">Sucursal</h3>
            <p className="text-[#FFFFFF] text-sm">Mexicali, Baja California</p>
            <p className="text-[#FFFFFF] text-sm">CP: 21353</p>
            <p className="text-[#FFFFFF] text-sm">Tel: +52 68 6000 0000</p>
          </div>
        </div>

        {/* Nueva Sección: Logo de Sectur */}
        <div className="md:w-1/3 flex justify-end">
          <img src="images/sectur.png" alt="Sectur" className="h-12" />
        </div>
      </div>

      {/* Aviso de Privacidad y Derechos Reservados */}
      <div className="text-center mt-4">
        <p className="text-xs mb-1">
          <a href="/aviso-privacidad" className="text-[#FFFFFF] hover:underline">Aviso de Privacidad</a>
        </p>
        <p className="text-[#FFFFFF] text-xs">&copy; 2024 HeavenlyTrip. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
