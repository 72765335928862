import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const navigate = useNavigate(); 

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    
    if (window.location.pathname !== '/') {
      navigate('/');
      
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, 100); 
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  return (
    <nav className="bg-[#FFFFFF] p-3 fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center text-white text-2xl font-bold">
          <a href="/">
            <img src="images/logo.svg" alt="HeavenlyTrip" className="h-12" />
          </a>
        </div>
        <div className="md:hidden">
          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-300 hover:text-white focus:outline-none"
            aria-label="Toggle menu"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>

        <div className="hidden md:flex space-x-4">
          <a href="/" onClick={(event) => {scrollToSection(event); setIsOpen(false);}} className="text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Inicio</a>
          <a href="#nosotros" onClick={(event) => {scrollToSection(event, 'nosotros'); setIsOpen(false);}} className="text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Nosotros</a>
          <div 
            className="relative"
            style={{ top: '5px', left: '0' }} 
            onMouseEnter={() => setIsServicesOpen(true)} 
            onMouseLeave={() => setIsServicesOpen(false)}
          >
            <a 
              href="#servicios" 
              onClick={(event) => {scrollToSection(event, 'servicios'); setIsOpen(false);}}
              className="text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium"
            >
              Servicios
            </a>
            {isServicesOpen && (
              <div className="absolute bg-white rounded-md shadow-lg z-10 min-w-[150px]">
                <button
                  onClick={() => navigate('/hoteles')}
                  className="block w-full text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium text-left"
                >
                  Hoteles
                </button>
                <button
                  onClick={() => navigate('/tours')}
                  className="block w-full text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium text-left"
                >
                  Tours
                </button>
                <button
                  onClick={() => navigate('/traslados')}
                  className="block w-full text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium text-left"
                >
                  Traslados
                </button>
                <button
                  onClick={() => navigate('/renta-autos')}
                  className="block w-full text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium text-left"
                >
                  Renta de autos
                </button>
                <button
                  onClick={() => navigate('/daypass')}
                  className="block w-full text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium text-left"
                >
                  Day pass
                </button>
                <button
                  onClick={() => navigate('/circuitos')}
                  className="block w-full text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium text-left"
                >
                  Circuitos
                </button>
              </div>
            )}
          </div>
          <a href="https://viaje.ly/aheavenlytrip" target="_blank" rel="noopener noreferrer" className="text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Internacionales</a>
          <a href="#contacto" onClick={(event) => {scrollToSection(event, 'contacto'); setIsOpen(false);}} className="text-[#009fe3] bg-white hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Contacto</a>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden bg-white">
          <a href="/" onClick={(event) => {scrollToSection(event); setIsOpen(false);}} className="block text-[#009fe3] hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Inicio</a>
          <a href="#nosotros" onClick={(event) => {scrollToSection(event, 'nosotros'); setIsOpen(false);}} className="block text-[#009fe3] hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Nosotros</a>
          <a href="#servicios" onClick={(event) => {scrollToSection(event, 'servicios'); setIsOpen(false);}} className="block text-[#009fe3] hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Servicios</a>
          <a href="https://viaje.ly/aheavenlytrip" target="_blank" rel="noopener noreferrer" className="block text-[#009fe3] hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Internacionales</a>
          <a href="#contacto" onClick={(event) => {scrollToSection(event, 'contacto'); setIsOpen(false);}} className="block text-[#009fe3] hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">Contacto</a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
