import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const servicesData = [
  { title: 'Hoteles', image: 'images/hoteles.jpg', description: 'Reserva los mejores hoteles para tu estadía.', route: '/hoteles' },
  { title: 'Tours', image: 'images/tours.jpg', description: 'Explora destinos increíbles con nuestros tours guiados.', route: '/tours' },
  { title: 'Traslados', image: 'images/traslados.jpg', description: 'Servicio de traslados cómodos y seguros.', route: '/traslados' },
  { title: 'Renta de autos', image: 'images/renta_de_autos.jpg', description: 'Alquila un auto y viaja a tu ritmo.', route: '/renta-autos' },
  { title: 'Day pass', image: 'images/daypass.jpg', description: 'Disfruta de un día completo en resorts de lujo.', route: '/daypass' },
  { title: 'Circuitos', image: 'images/circuitos.jpg', description: 'Viaja por diferentes continentes con nuestros circuitos organizados.', route: '/circuitos' },
];

const Services = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.2, 
      }
    );

    const element = document.getElementById('servicios');
    observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <div
      id="servicios"
      className={`py-12 px-4 bg-white rounded-md transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <h2 className="text-center text-3xl font-bold mb-10 text-gray-800">Servicios</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
        {servicesData.map((service, index) => (
          <div
            key={index}
            className="bg-white p-5 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col"
          >
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-32 object-cover mb-4 rounded-lg"
            />
            <h3 className="text-xl font-semibold mb-2 text-gray-800">{service.title}</h3>
            <p className="text-sm text-gray-600 mb-4 flex-grow">{service.description}</p>
            <div className="border-t border-gray-300 my-4" />
            <Link 
              to={service.route} 
              className="inline-block bg-[#009fe3] text-white py-2 px-4 rounded-lg hover:bg-[#007bbf] transition duration-300 self-end"
            >
              Más información
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
