import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import ReservationModal from '../components/FormularioReservationModal';
import Footer from '../components/Footer';
import { getHotelesActivos } from '../services/Services';

const HotelCatalogo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null); 
  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    whatsapp: '',
    email: '',
    comentarios: ''
  });

  const [hoteles, setHoteles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchHoteles = async () => {
    try {
      const response = await getHotelesActivos();
      const { hoteles } = response;

      const hotelesConImagen = await Promise.all(hoteles.map(async hotel => {
        if (hotel.imagen) {
          const imageUrl = URL.createObjectURL(new Blob([new Uint8Array(hotel.imagen.data)], { type: 'image/jpeg' }));
          return { ...hotel, imageUrl };
        }
        return hotel;
      }));

      setHoteles(hotelesConImagen);
    } catch (error) {
      setError(error.message || "Error al obtener los hoteles");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHoteles();
    const socket = new WebSocket(process.env.REACT_APP_API_URL);

    socket.onopen = () => {
      //console.log('Conectado al WebSocket');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'UPDATE_HOTEL') {
        fetchHoteles();
      }
    };
    socket.onclose = () => {
      //console.log('Desconectado del WebSocket');
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setFormData({
        nombre: '',
        telefono: '',
        whatsapp: '',
        email: '',
        comentarios: ''
      });
    }
  }, [isModalOpen]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleReservaClick = (hotel) => {
    setSelectedHotel(hotel);
    setIsModalOpen(true);
  };
  if (loading) {
  }

  if (error) {
  }
  return (
    <div>
      <Navbar />
      <div className="relative h-screen bg-gray-200 overflow-hidden">
        <div
          className={`absolute inset-0 bg-cover bg-center`}
          style={{ backgroundImage: `url(images/hoteles.jpg)` }}
        >
          <div className="flex flex-col items-center justify-center h-full bg-black bg-opacity-50">
            <h1 className="text-3xl font-semibold text-white text-center">HOTELES</h1>
            <p className="mt-2 text-lg text-white text-center">Reserva los mejores hoteles para tu estadía</p>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 py-8 px-4">
        <div className="max-w-5xl mx-auto">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Más hoteles para ti</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {hoteles.map((hotel, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-sm flex flex-col justify-between">
                <img
                  src={hotel.imageUrl}
                  alt={`Imagen de ${hotel.nombre}`}
                  className="w-full h-48 object-cover mb-3 rounded-t-lg cursor-pointer"
                  onClick={() => handleImageClick(hotel.imageUrl)}
                />
                <div className="flex flex-col justify-between flex-grow p-3">
                  <h4 className="text-base font-semibold text-gray-800 mb-1">{hotel.nombre}</h4>
                  <p className="text-xs text-gray-600">{hotel.descripcion}</p>
                  <div className="border-t border-gray-300 my-3" />
                  <button
                    onClick={() => handleReservaClick(hotel)}
                    className="inline-block bg-blue-500 text-white py-1 px-3 rounded-lg hover:bg-blue-600 transition duration-300 self-end text-sm"
                  >
                    Reservar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={closeImageModal}>
          <img src={selectedImage} alt="Imagen grande" className="max-w-full max-h-full" />
        </div>
      )}
      {/* Modal de Reserva */}
      <ReservationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        formData={formData}
        handleInputChange={handleInputChange}
        idSeccion={selectedHotel ? selectedHotel.idSeccion : null} 
        idServicio={selectedHotel ? selectedHotel.idHotel : null}
      />
      <Footer />
    </div>
  );
};


export default HotelCatalogo;
