import React, { useEffect, useState } from 'react';

const Nosotros = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.2, 
      }
    );

    const element = document.getElementById('nosotros');
    observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <div
      id="nosotros"
      className={`py-12 px-4 bg-white rounded-md transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-6 items-center">
        <div className="order-2 lg:order-1">
          <h2 className="text-3xl font-semibold mb-4">¿Quiénes somos?</h2>
          <p className="text-base text-gray-600 mb-4">
            Con más de 9 años de experiencia en la industria del turismo, nuestra agencia nació
            de una pasión por explorar y compartir lo mejor de Cancún y la Riviera Maya. Lo que
            comenzó como un hobbie se transformó en una agencia reconocida por su profundo
            conocimiento y expertise en nuestros destinos. Nos enorgullece ofrecer a nuestros
            clientes experiencias únicas, personalizadas y llenas de aventura en los rincones más
            paradisíacos de México.
          </p>
        </div>
        <div className="order-1 lg:order-2">
          <img
            src="images/nosotros.jpg"
            alt="Sobre nosotros"
            className="w-full h-72 object-cover rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Nosotros;
