import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; 

export const getHotelesActivos = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/getHotelesActivos`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};
export const getToursActivos = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/getToursActivos`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};
export const getTrasladosActivos = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/getTrasladosActivos`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};
export const getCircuitosActivos = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/getCircuitosActivos`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};
export const getDaypassActivos = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/getDaypassActivos`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};
export const getRentaAutosActivos = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/getRentaDeAutosActivos`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};
export const agregarReservacion = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/api/reservar`, formData);
    return response.data; 
  } catch (error) {
    throw error;
  }
};
