import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Inicio from './pages/Inicio'; 
import HotelesCatalogo from './pages/HotelesCatalogo'; 
import ToursCatalogo from './pages/ToursCatalogo'
import TrasladosCatalogo from './pages/TrasladosCatalogo'
import RentaAutosCatalogo from './pages/RentaAutosCatalogo'
import DayPassCatalogo from './pages/DayPassCatalogo'
import CircuitosCatalogo from './pages/CircuitosCatalogo'
import ScrollToTop from './components/scrollToTop'; 


const App = () => {
  return (
    <Router>
      <ScrollToTop />
        <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/hoteles" element={<HotelesCatalogo/>} />
        <Route path="/tours" element={<ToursCatalogo/>} />
        <Route path="/traslados" element={<TrasladosCatalogo/>} />
        <Route path="/renta-autos" element={<RentaAutosCatalogo/>} />
        <Route path="/daypass" element={<DayPassCatalogo/>} />
        <Route path="/circuitos" element={<CircuitosCatalogo/>} />
          {/* Añade las rutas que correspondan a cada servicio */}
        </Routes>
    </Router>
  );
};

export default App;
