import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Services from './Servicios';
import Nosotros from './Nosotros';
import Footer from '../components/Footer';

const images = [
  'images/carousel1.jpg', 
  'images/carousel2.jpg',
  'images/carousel3.jpg',
];

const Inicio = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId); 
  }, []);

  return (
    <div>
      <Navbar />

      {/* Carousel */}
      <div className="relative h-screen bg-gray-200 overflow-hidden pt-16">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
            style={{ backgroundImage: `url(${image})` }}
          >
            <div className="flex flex-col items-center justify-center h-full bg-black bg-opacity-50">
              <h1 className="text-3xl sm:text-4xl md:text-4xl font-bold text-white text-center">Viaja, descubre y vive el paraíso. ¡Nosotros te llevamos!</h1>
              <p className="mt-4 text-base sm:text-lg md:text-xl text-white text-center">Reserva con nosotros</p>
            </div>
          </div>
        ))}
      </div>

      {/* Sección "Quiénes somos" */}
      <div id="nosotros" className="py-10 px-4 bg-gray-200">
        <Nosotros />
      </div>

      {/* Sección "Servicios" */}
      <div id="servicios" className="py-10 px-4 bg-gray-200">
        <Services />
      </div>

      {/* Sección "Contacto" */}
      <div id="contacto" className="bg-gray-200">
        <Footer />
      </div>
    </div>
  );
};

export default Inicio;