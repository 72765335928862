import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import ReservationModal from '../components/FormularioReservationModal';
import { getToursActivos } from '../services/Services'; 
import Footer from '../components/Footer';

const TourCatalogo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null); 

  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    whatsapp: '',
    email: '',
    comentarios: ''
  });

  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchTours = async () => {
    try {
      const response = await getToursActivos();
      const { tours } = response;

      const toursConImagen = await Promise.all(tours.map(async tour => {
        if (tour.imagen) {
          const imageUrl = URL.createObjectURL(new Blob([new Uint8Array(tour.imagen.data)], { type: 'image/jpeg' }));
          return { ...tour, imageUrl };
        }
        return tour;
      }));

      setTours(toursConImagen);
    } catch (error) {
      setError(error.message || "Error al obtener los tours");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTours();

    const socket = new WebSocket(process.env.REACT_APP_API_URL);

    socket.onopen = () => {
      //console.log('Conectado al WebSocket');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'UPDATE_TOUR') {
        fetchTours();
      }
    };

    socket.onclose = () => {
      //console.log('Desconectado del WebSocket');
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setFormData({
        nombre: '',
        telefono: '',
        whatsapp: '',
        email: '',
        comentarios: ''
      });
    }
  }, [isModalOpen]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleReservaClick = (tour) => {
    setSelectedTour(tour);
    setIsModalOpen(true);
  };

  if (loading) {
  }

  if (error) {
  }

  return (
    <div>
      <Navbar />
      <div className="relative h-screen bg-gray-200 overflow-hidden">
        <div
          className={`absolute inset-0 bg-cover bg-center`}
          style={{ backgroundImage: `url(images/tours.jpg)` }}
        >
          <div className="flex flex-col items-center justify-center h-full bg-black bg-opacity-50">
            <h1 className="text-3xl font-semibold text-white text-center">TOURS</h1>
            <p className="mt-2 text-base text-white text-center">Reserva los mejores tours para tu estadía</p>
          </div>
        </div>
      </div>
  
      <div className="bg-gray-100 py-8 px-4">
        <div className="max-w-5xl mx-auto">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Más tours para ti</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {tours.map((tour, index) => (
              <div key={index} className="bg-white p-3 rounded-lg shadow-sm flex flex-col justify-between">
                <img 
                  src={tour.imageUrl} 
                  alt={`Imagen de ${tour.nombre}`} 
                  className="w-full h-64 object-cover mb-3 rounded-lg cursor-pointer"
                  onClick={() => handleImageClick(tour.imageUrl)} 
                />
                <div className="flex flex-col justify-between flex-grow p-3"> 
                  <h4 className="text-xl font-semibold mb-2 text-gray-800">{tour.nombre}</h4>
                  <p className="text-xs text-gray-600">{tour.descripcion}</p>
                  <div className="border-t border-gray-300 my-3" />
                  <button 
                    onClick={() => handleReservaClick(tour)}
                    className="inline-block bg-blue-500 text-white py-1 px-3 rounded-lg hover:bg-blue-600 transition duration-300 self-end text-sm"
                  >
                    Reservar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
  
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={closeImageModal}>
          <img src={selectedImage} alt="Imagen grande" className="max-w-full max-h-full" />
        </div>
      )}
  
      <ReservationModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        formData={formData} 
        handleInputChange={handleInputChange} 
        idSeccion={selectedTour ? selectedTour.idSeccion : null} 
        idServicio={selectedTour ? selectedTour.idTour : null}
      />
      <Footer />
    </div>
  );  
};

export default TourCatalogo;
